/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:root {
  --primaryColor: #af9a7d;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  padding-top: 66px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
}
h1 {
  font-size: 3em;
  /* line-height: 1; */
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  -webkit-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0.75rem 0;
  background: var(--offWhite);
  z-index: 1;
}
.nav-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
  height: 0;
  overflow: hidden;
  -webkit-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
  transition: var(--mainTransition);
  list-style-type: none;
}
.nav-links a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  -webkit-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links li a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  top: 40px;
  left: 0;
  background: var(--primaryColor);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
}
.nav-links li a:hover {
  color: var(--primaryColor);
}

.nav-links li a:hover::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
}
.show-nav {
  height: 150px;
}
@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .hamburger {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .nav-links {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - 66px);
  background: url("./images/pokoje/dom/dom.jpg") center/cover no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.roomsHero {
  background-image: url("./images/pokoje/dom/dom_1.jpg");
  min-height: 60vh;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  /* letter-spacing: var(--mainSpacing); */
  width: 100vw;
  /* -webkit-animation-duration: 2s;

  animation-duration: 2s;
  -webkit-animation-name: animeBanner;
  animation-name: animeBanner; */
}
.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
    width: 60vw;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 5rem 0;
  min-height: cal(80vh - 66px);
}
.services {
  background: var(--darkGrey);
  text-align: center;
}
.services-center {
  width: 85vw;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  letter-spacing: var(--mainSpacing);
}
.services p {
  width: 80%;
  margin: 0 auto;
}

@media screen and (min-width: 992px) {
  .services-center {
    width: 65vw;
    max-width: 800px;
    width: 85vw;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .services-center {
    width: 65vw;
    max-width: 1180px;
    width: 85vw;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
  min-height: calc(80vh - 66px);
}
.featured-rooms-center {
  /* width: 65vw; */
  margin: 0 1rem;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 65vw;
    grid-template-columns: repeat(auto-fit, minmax(262px, 1fr));
  }
}

@media screen and (min-width: 992px) {
  .featured-rooms-center {
    margin: 0 auto;
    width: 65vw;
    max-width: 1050px;
    width: 85vw;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .services-center {
    width: 65vw;
    max-width: 1180px;
    width: 85vw;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .services-center {
    width: 65vw;
    max-width: 1180px;
    width: 85vw;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
}

/* end pf featured rooms */
/* room */
.room {
  -webkit-box-shadow: var(--lightShadow);
  box-shadow: var(--lightShadow);
  -webkit-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
  transition: var(--mainTransition);
}
.room:hover {
  -webkit-box-shadow: var(--darkShadow);
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  -webkit-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
  transition: var(--mainTransition);
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  -webkit-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
  transition: var(--mainTransition);
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .room-link {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.room-info {
  background: var(--darkGrey);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
}
/* end of room  */
/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 90vw;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 50px;
}

.single-room-images img {
  width: 100%;
  display: block;
  height: 30vh;
}
.single-room-info {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-left: 4rem;
  margin-top: 3rem;
}
.info {
  margin: 1rem 0 0 16rem;
}

.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 2rem 0 3rem 0rem;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.extras > li {
  padding-top: 1rem;
}

@media screen and (max-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
    margin-left: 0;
  }
  .info {
    padding-left: 0rem;
    margin-left: 0;
  }
  .room-extras {
    margin-left: 0;
  }
}
/* end of single room*/
/* roomlist */
.roomlist {
  margin-bottom: 2rem;
}

.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */
/*  rooms fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms fitler*/
/*form*/
.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.wrapper {
  -webkit-box-shadow: 0 0 20px 0 rgba(72, 94, 116, 0.7);
  box-shadow: 0 0 20px 0 rgba(72, 94, 116, 0.7);
}

.wrapper > * {
  padding: 1em;
}

.company-info {
  background: #c9e6ff;
}

.company-info h3,
.company-info ul {
  text-align: center;
  margin: 0 0 1rem 0;
}

.contact {
  background: #f9feff;
}

/* FORM STYLES */
.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.wrapper {
  -webkit-box-shadow: 0 0 20px 0 rgba(72, 94, 116, 0.7);
  box-shadow: 0 0 20px 0 rgba(72, 94, 116, 0.7);
}

.wrapper > * {
  padding: 1em;
}

.company-info {
  background: #c9e6ff;
}

.company-info h3,
.company-info ul {
  text-align: center;
  margin: 0 0 1rem 0;
}

.contact {
  background: #f9feff;
}

/* FORM STYLES */

.contact form label {
  display: block;
}

.contact form p {
  margin: 0;
}

.contact form .full {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
}

.contact form button,
.contact form input,
.contact form textarea {
  width: 100%;
  padding: 1em;
  border: 1px solid #c9e6ff;
}

.contact form button {
  background: #c9e6ff;
  border: 0;
  text-transform: uppercase;
}

.contact form button:hover,
.contact form button:focus {
  background: #92bde7;
  color: #fff;
  outline: 0;
  -webkit-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

.contact form > p {
  margin-top: 1rem;
}
.contact .recaptcha {
  width: 50vw;
}
/* LARGE SCREENS */
@media (min-width: 700px) {
  .wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 2fr;
    grid-template-columns: 1fr 2fr;
  }
  .contact .recaptcha {
    width: 91%;
  }
  .contact form {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .contact form .recaptcha {
    margin-left: 50%;
  }

  .wrapper > * {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
  .company-info ul li {
    margin-top: 15px;
  }

  .dolar {
    position: absolute;
    -webkit-transform: translateY(70%);
    -ms-transform: translateY(70%);
    transform: translateY(70%);
  }

  .marginLeft {
    margin-left: 20px;
  }
}
/*form end*/

.formError {
  border: 2px solid red !important;
}
/*map*/
.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 60vh;
}

.pin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 2rem;
  color: red;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    color: red;
    font-size: 5vw;
  }
}
/*map end */

/*footer*/
.socialMedia span {
  height: 35px;
}

.socialMedia h3 {
  margin-bottom: 0;
}
.socialMedia span:hover {
  border-top-right-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.5s ease-in-out;
  transition: -webkit-box-shadow 0.5s ease-in-out;
  -o-transition: box-shadow 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out, -webkit-box-shadow 0.5s ease-in-out;
  -webkit-box-shadow: 10px -12px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px -12px 5px 0px rgba(0, 0, 0, 0.75);
}

.socialMedia span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
}

.footerNav {
  text-decoration: none;
  list-style-type: none;
  margin-left: 15px;
}
.footerNav a {
  text-decoration: none;
  color: black;
  position: relative;
  -webkit-transition: left ease-in 0.3s;
  -o-transition: left ease-in 0.3s;
  transition: left ease-in 0.3s;
  left: 0;
}

.footerNav li a:hover {
  left: 10px;
  color: blue;
}

.socialMedia {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-direction: row; */
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: center;
  align-content: center;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.socialMedia:nth-child(2) {
  width: 50vw;
}

.socialMedia:nth-child(3) {
  margin-left: 1rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.socialMedia {
  padding-top: 2rem;
  margin-left: -9rem;
}
@media screen and (min-width: 1024px) {
  .footer {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: var(--darkGrey);
  }

  .socialMedia {
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
    width: 45vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .socialMedia {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 0;
  }
  .socialMedia span {
    display: inline-block;
    color: var(--primaryColor);
    font-size: 2.5rem;
  }
}

.footerInfo {
  margin-left: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

/*footer end*/
.img-hover-zoom {
  /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  height: 30vh;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.line,
.subtitle,
.children,
.title {
  opacity: 0;
}

.children {
  display: inline-block;
}
@-webkit-keyframes animeOpacity {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animeOpacity {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.animeText {
  -webkit-animation-name: text;
  animation-name: text;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes text {
  from {
    -webkit-transform: translateX(+3343px);
    transform: translateX(+3343px);
  }
  to {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes text {
  from {
    -webkit-transform: translateX(+3343px);
    transform: translateX(+3343px);
  }
  to {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes animeBanner {
  from {
    -webkit-transform: translateY(-3333px);
    transform: translateY(-3333px);
  }
  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes animeBanner {
  from {
    -webkit-transform: translateY(-3333px);
    transform: translateY(-3333px);
  }
  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/*hambureger menu*/
.hamburger {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
}

.hamburger {
  -webkit-transition: -webkit-transform 0.3s 0.1s ease-in-out;
  transition: -webkit-transform 0.3s 0.1s ease-in-out;
  -o-transition: transform 0.3s 0.1s ease-in-out;
  transition: transform 0.3s 0.1s ease-in-out;
  transition: transform 0.3s 0.1s ease-in-out,
    -webkit-transform 0.3s 0.1s ease-in-out;
}

.hamburger--active {
  -webkit-transform: translatex(250px);
  -ms-transform: translatex(250px);
  transform: translatex(250px);
}

.hamburger__box {
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-line {
  width: 100%;
  height: 3px;
  background-color: black;
  position: absolute;
}

.hamburger__inner {
  width: 100%;
  height: 3px;
  background-color: black;
  position: absolute;

  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: background-color 0.1s 0.2s ease-in-out;
  -o-transition: background-color 0.1s 0.2s ease-in-out;
  transition: background-color 0.1s 0.2s ease-in-out;
}

.hamburger__inner::before,
.hamburger__inner::after {
  width: 100%;
  height: 3px;
  background-color: black;
  position: absolute;

  content: "";
  left: 0;
  -webkit-transition: -webkit-transform 0.2s 0.2s ease-in-out;
  transition: -webkit-transform 0.2s 0.2s ease-in-out;
  -o-transition: transform 0.2s 0.2s ease-in-out;
  transition: transform 0.2s 0.2s ease-in-out;
  transition: transform 0.2s 0.2s ease-in-out,
    -webkit-transform 0.2s 0.2s ease-in-out;
}

.hamburger__inner::before {
  top: -10px;
}

.hamburger__inner::after {
  top: 10px;
}

.hamburger--active .hamburger__inner {
  background-color: transparent;
}

.hamburger--active .hamburger__inner:before {
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}

.hamburger--active .hamburger__inner:after {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
}

@media screen and (min-width: 799px) {
  .hamburgerMenu {
    display: none;
  }

  .fade-in {
    opacity: 0;
    -webkit-animation: slideOut 1s;
    animation: slideOut 1s;
  }

  .appear {
    opacity: 1;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
  }
}

.fade-in {
  opacity: 0;
}

.appear {
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
}

/*hambureger menu end*/

.aboutUs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 0;
}

@-webkit-keyframes slidein {
  from {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slideOut {
  from {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }

  to {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    opacity: 0;
  }
}

@keyframes slideOut {
  from {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }

  to {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    opacity: 0;
  }
}

/* modal  */

.modalBody {
  margin: 0;
  background-image: url("../src/images//wielkanoc.jpg");
  min-height: 100vh;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.modalBody h1 {
  text-align: center;
  /* line-height: 3rem; */
  font-weight: bold;
  font-size: 2rem;
  padding: 2rem 0;
  color: lightgoldenrodyellow;
}
.countdown-container {
  display: flex;
  flex-direction: row;
  padding: 2rem 0;
  color: lightgoldenrodyellow;
}

.big-text {
  font-weight: bold;
  font-size: 3rem;
  line-height: 1;
  margin: 0 10px;
}

.countdown-el {
  text-align: center;
}

.countdown-el span {
  font-size: 10px;
  padding: 0 10px;
}

@media screen and (min-width: 600px) {
  .modalBody {
    margin: 0;
    background-image: url("../src/images/wielkanoc.jpg");
    min-height: 100vh;
    /* min-width: 100vw; */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    align-items: center;
    justify-content: center;
  }
  .modalBody h1 {
    margin-top: -5rem;
    font-weight: normal;
    font-size: 5rem;
  }
  .countdown-container {
    display: flex;
    flex-direction: row;
  }

  .big-text {
    font-weight: bold;
    font-size: 6rem;
    line-height: 1;
    margin: 0 2rem;
  }

  .countdown-el {
    text-align: center;
  }

  .countdown-el span {
    font-size: 1.5rem;
    padding: 0 3rem;
  }
}

/* modal end */

/* button-newyears */

.button-newyears {
  display: inline-block;
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button-newyears span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button-newyears span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: -10;
  right: 0px;
  transition: 0.5s;
}

.button-newyears:hover span {
  padding-right: 25px;
}

.button-newyears:hover span:after {
  opacity: 1;
  right: 0;
}

/* button-newyears ends */

/* newyeras offert */

.newyearsoffer {
  max-width: 100vw;
  /* margin: 0 2rem; */
  padding: 2rem 2rem;
  /* background-image: url("https://live.staticflickr.com/8016/7476065576_72956c21fa_b.jpg"); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  /* background: #000; */
}

.newyearsoffer .center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80vw;
  color: black;
}

.newyear-center {
  display: table;
  margin: 2rem auto;
  font-size: 2rem;
}

.date {
  font-size: 1.5rem;
}

.newyear-list {
  margin-top: 2rem;
  justify-content: center;
  margin-left: 0rem;
}

.newyear-header {
  color: yellow;
  margin: 0 auto;
  display: table;
  font-size: 3rem;
  background-color: coral;
  text-align: center;
}

.newyearsoffer-footer {
  background-color: #92a8d1;
  margin-top: 2rem;
}

@media screen and (min-width: 600px) {
  .newyearsoffer {
    margin: 0 2rem;
    padding: 2rem 2rem;
    /* background-image: url("https://live.staticflickr.com/8016/7476065576_72956c21fa_b.jpg"); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(18, 1, 1);
    /* background: #000; */
  }

  .newyearsoffer .center {
    /* margin: 0 rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70vw;
    margin-left: 2rem;
  }

  .newyear-list {
    margin-top: 2rem;
    display: flex;
    margin-left: 0rem;
  }
  .newyear-list li {
    margin-left: 1rem;
    width: 13vw;
  }

  .newyearsoffer .center {
    margin-left: 12rem;
  }

  .newyearsoffer-footer {
    background-color: transparent;
    margin-top: 2rem;
  }
}

/* oferta swieta sylwester */
.containerSylSwieta {
display: flex; 
width: 100%;
}

.innercontainerSylSwieta{
  position: relative;
  width: 50%;
}
.imageStyle{
  width: 100%;
  height: 100%;
}

.btnStyleSylSwieta {
position: absolute;
top: 80%;
left: 50%;
transform: translate(-50%, -20%);
-ms-transform: translate(-50%, -20%);
background-color: #555;
color: white;
font-size: 16px;
padding: 12px 24px;
border: none;
cursor: pointer;
border-radius: 5px;
text-decoration: none;
}

.containerSylSwieta .btnStyleSylSwieta:hover {
  background-color: rgb(189, 35, 35);
}

@media screen and (max-width: 600px) {
  .containerSylSwieta{ 
    display: inline;
    height: 100%;
    width: 100%;
  }

  .innercontainerSylSwieta{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .imageStyle{
    width: 100%;
    height: 100%;
  }
}

.center {
  text-align: center;
}

.swietaflex {
  display: flex;
  width: 100%
}
/* koniec oferty swieta swylwester */




/*gallery*/
.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .galleryWrap {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 620px;
    margin: 0 auto;
  }
}

.galleryWrap .single {
  cursor: pointer;
}

.galleryWrap .single img {
  width: 8vw;
  height: 20vh;
}

 @media screen and (max-width: 600px) {
  .galleryWrap .single img {
    width: 16vw;
    height: 10vh;
  }
} 

.galleryWrap .single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover, .btnPrev:hover, .btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  all: unset;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  all: unset;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */  
}